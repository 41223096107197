import { ReactNode } from "react";
import { CSSRuleObject } from "tailwindcss/types/config";

export interface HeadingProps {
  as?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "div"
    | "span"
    | "legend"
    | "label";
  className?: string;
  children: ReactNode;
  display?: boolean;
  id?: string;
  role?: "columnheader" | "heading";
  style?: CSSRuleObject;
}

export function Heading({
  as,
  children,
  className,
  display,
  id,
  style,
}: HeadingProps) {
  const Element = as ?? "h4";

  const fontWeight = className?.includes("font-") ? "" : "font-[500]";
  const leading = className?.includes("leading-") ? "" : "leading-tight";
  const tracking = className?.includes("tracking-") ? "" : "tracking-tight";

  return (
    <Element
      className={`${
        display ? "font-display" : "font-sans"
      } ${fontWeight} ${leading} ${tracking} ${className ?? ""}`}
      id={id}
      style={style}
    >
      {children}
    </Element>
  );
}
