import type { Config } from "tailwindcss";
import colors from "tailwindcss/colors";
import theme from "tailwindcss/defaultTheme";

const customColors = {
  alert: {
    50: "#fdf6ef",
    100: "#fbe9d9",
    200: "#f7d0b1",
    300: "#f3bc94",
    400: "#ea864d",
    500: "#e5662a",
    600: "#d64e20",
    700: "#b23b1c",
    800: "#8e301e",
    900: "#732a1b",
    950: "#3e130c",
  },
  black: "#000000",
  counterGreen: {
    50: "#effaf9",
    100: "#e0f4f1",
    200: "#b6e4e0",
    300: "#87cecc",
    400: "#54b3b2",
    500: "#00999e",
    600: "#237378",
    700: "#1c5a60",
    800: "#18494d",
    900: "#153b3f",
    950: "#0b1f23",
  },
  yellowNews: {
    200: "#FDF18B",
    300: "#FBE349",
  },
  cream: {
    50: "#FFFDF9",
    100: "hsl(40, 20%, 97%)",
    200: "hsl(40, 20%, 94%)",
    300: "hsl(40, 20%, 90%)",
    400: "hsl(40, 20%, 86%)",
    500: "hsl(40, 20%, 82%)",
  },
  gray: {
    4: "#2D3748",
    50: "#F6F7F9",
    100: "#edeef1",
    200: "#d6dae1",
    300: "#b2bbc7",
    400: "#8996a7",
    500: "#6a798d",
    600: "#576477",
    700: "#454f5f",
    800: "#3c4450",
    900: "#353b45",
    950: "#23272e",
  },
  green: {
    "200alpha005": "hsla(171, 48%, 60%, 0.05)",
  },
  lucky: {
    50: "#f9fcea",
    100: "#f4fac7",
    200: "#eaf593",
    300: "#e7f054",
    400: "#e5e926",
    500: "#d9d419",
    600: "#ac9c11",
    700: "#967d12",
    800: "#7c6217",
    900: "#6a5119",
    950: "#3e2c0a",
  },
  orange: {
    50: "#fdf6ef",
    100: "#fbe9d9",
    200: "#f7d0b1",
    300: "#f3bc94",
    400: "#ea864d",
    500: "#e5662a",
    600: "#d64e20",
    700: "#b23b1c",
    800: "#8e301e",
    900: "#732a1b",
    950: "#3e130c",
  },
  purple: "#444960",
  rose: {
    500: "#EF4444",
  },
  slate: {
    50: "hsl(211, 25%, 97%)",
  },
  refill: {
    50: "#F5FFE6",
    950: "#163003",
    300: "#BAF96E",
    900: "#305611",
  },
  white: "#FFFFFF",
};

const config = {
  content: ["./src/**/*.{js,ts,jsx,tsx}"],
  darkMode: "media",
  mode: "jit",
  theme: {
    customForms: (theme: any) => ({
      default: {
        input: {
          borderRadius: theme("borderRadius.lg"),
          backgroundColor: theme("colors.gray.200"),
          "&:focus": {
            backgroundColor: theme("colors.white"),
          },
        },
        select: {
          borderRadius: theme("borderRadius.lg"),
          boxShadow: theme("boxShadow.default"),
        },
        radio: {
          borderRadius: theme("borderRadius.4xl"),
          boxShadow: theme("boxShadow.default"),
          color: theme("colors.green.default"),
          iconColor: theme("colors.green.default"),
          backgroundColor: theme("colors.green.default"),
        },
        checkbox: {
          width: theme("spacing.6"),
          height: theme("spacing.6"),
        },
      },
    }),
    plugins: [require("@tailwindcss/custom-forms")],
    extend: {
      backgroundColor: {
        button: {
          primary: {
            active: customColors.counterGreen[800],
            default: customColors.counterGreen[600],
            hover: customColors.counterGreen[700],
          },
          secondary: {
            default: colors.black,
            hover: colors.neutral[900],
          },
          tertiary: {
            active: customColors.counterGreen[300],
            default: customColors.counterGreen[100],
            hover: customColors.counterGreen[200],
          },
          warning: colors.amber[100],
        },
        card: {
          active: colors.gray[100],
          hover: colors.gray[50],
        },
        disabled: colors.neutral[300],
        error: {
          DEFAULT: colors.red[50],
        },
        focus: colors.neutral[200],
        icon: {
          DEFAULT: customColors.counterGreen[100],
          success: customColors.counterGreen[600],
        },
        info: {
          DEFAULT: customColors.counterGreen[100],
        },
        input: {
          disabled: colors.neutral[100],
          error: colors.red[500],
          track: colors.neutral[300],
        },
        muted: {
          DEFAULT: colors.gray[200],
        },
        success: {
          DEFAULT: colors.emerald[50],
        },
        tertiary: {
          DEFAULT: colors.emerald[500],
        },
        "touch-active": colors.neutral[50],
        warning: {
          DEFAULT: customColors.orange[100],
        },
      },
      borderColor: {
        bar: customColors.gray[100],
        button: {
          transparent: {
            active: colors.black,
            DEFAULT: colors.gray[300],
            hover: colors.gray[600],
          },
        },
        card: {
          DEFAULT: colors.gray[300],
          hover: colors.gray[400],
          active: colors.emerald[500],
        },
        DEFAULT: colors.gray[200],
        divider: colors.gray[300],
        input: {
          checked: customColors.counterGreen[600],
          default: colors.gray[300],
          disabled: colors.gray[200],
          error: colors.red[500],
          focus: colors.black,
        },
      },
      borderRadius: {
        badge: theme.borderRadius["DEFAULT"],
        button: theme.borderRadius["lg"],
        card: theme.borderRadius["xl"],
        dialog: theme.borderRadius["2xl"],
        input: theme.borderRadius["lg"],
        message: theme.borderRadius["lg"],
      },
      borderWidth: {
        1: "1px",
        1.5: "1.5px",
        3: "3px",
      },
      boxShadow: {
        "3xl": "0 35px 60px -15px rgba(0, 0, 0, 0.3)",
        "inner-sm": "inset 0 1px 2px 0 rgb(0 0 0 / 0.05)",
      },
      brightness: {
        97: ".97",
        98: ".98",
      },
      colors: {
        accent: {
          DEFAULT: customColors.counterGreen[600],
        },
        alert: customColors.alert,
        blue: {
          1: "#F6FAFF",
          accent: "#004EC3",
        },
        cafe: {
          50: "hsl(50deg 40% 98%)",
          100: "hsl(70deg 30% 90%)",
        },
        rewards: {
          300: "#C4BAF8",
          600: "#7746E1",
          400: "#A693F2",
          50: "#F5F4FE",
          100: "#E9E6FC",
          700: "#6834CD",
          800: "#562BAC",
          900: "#48258D",
        },
        blueNews: {
          50: "#EDFCFE",
          100: "#D2F4FB",
          500: "#159EC5",
          600: "#147EA6",
        },
        counterGreen: {
          50: "#effaf9",
          100: "#e0f4f1",
          "100/30": "rgba(224, 244, 241, 0.30)",
          200: "#b6e4e0",
          300: "#87cecc",
          400: "#54b3b2",
          500: "#00999e",
          600: "#237378",
          700: "#1c5a60",
          800: "#18494d",
          900: "#153b3f",
          950: "#0b1f23",
        },
        yellowNews: {
          200: "#FDF18B",
          300: "#FBE349",
        },
        refill: {
          50: "#F5FFE6",
          700: "#44810A",
          950: "#163003",
          300: "#BAF96E",
          900: "#305611",
        },
        cream: {
          50: customColors.cream[50],
          100: customColors.cream[100],
          200: customColors.cream[200],
          300: customColors.cream[300],
          400: customColors.cream[400],
          500: customColors.cream[500],
        },
        gain: {
          DEFAULT: customColors.counterGreen[700],
          text: customColors.counterGreen[700],
        },
        graph: {
          active: {
            backgroundColor: customColors.counterGreen[500],
          },
          brand: {
            backgroundColor: colors.violet[400],
          },
          generic: {
            backgroundColor: colors.amber[400],
          },
          member: {
            backgroundColor: colors.sky[800],
          },
          muted: {
            backgroundColor: colors.slate[200],
          },
          plan: {
            backgroundColor: colors.lime[600],
          },
          specialty: {
            backgroundColor: colors.red[400],
          },
        },
        gray: {
          DEFAULT: "#D1D5DB",
          1: "#CBD5E0",
          2: "#A0AEC0",
          3: "#576477",
          4: "#2D3748",
          dark: "#92959D",
          medium: "#A7ABBF",
          light: "#FBFCFD",
          100: "#EDEEF1",
          950: "#23272E",
          ...customColors,
        },
        green: {
          ...customColors.counterGreen,
          DEFAULT: "#3CDBC0",
          accent: customColors.counterGreen[700],
          "accent-light": "#00999E",
          "accent-mid": "#99E1DD",
          "ultra-light": "#E0F4F1",
        },
        input: {
          checked: customColors.counterGreen[600],
          default: colors.neutral[50],
          focus: colors.white,
          track: colors.neutral[200],
        },
        link: {
          active: customColors.counterGreen[900],
          default: customColors.counterGreen[700],
          hover: customColors.counterGreen[800],
        },
        loss: {
          DEFAULT: customColors.rose[500],
          text: customColors.rose[500],
        },
        purple: {
          DEFAULT: customColors.purple,
        },
        rose: {
          500: customColors.rose[500],
        },
        table: {
          "grouped-cell": customColors.green["200alpha005"],
          "head-row": colors.slate[100],
          "row-border": colors.slate[100],
          "row-hover": colors.slate[100],
        },
        white: {
          DEFAULT: customColors.white,
          1: customColors.cream,
        },
      },
      fontFamily: {
        "Feijoa-Medium": ["var(--font-feijoa-medium, Feijoa Medium)", "serif"],
        display: ["var(--font-feijoa-medium, Feijoa Medium)", "serif"],
        handwriting: ["var(--font-caveat, Caveat)", "serif"],
        sans: ["var(--font-work-sans, Work Sans)", "sans-serif"],
      },
      fontSize: {
        "2xs": "11px",
        "3xs": "8px",
        "52px": "52px",
        xxs: "10px",
        xxxs: "6px",
      },
      fontWeight: {
        "table-header": "550",
        bold: "550",
      },
      height: {
        "42px": "42px",
        "440px": "440px",
        "450px": "450px",
        "500px": "500px",
        "540px": "540px",
        "650px": "650px",
        "740px": "740px",
      },
      lineHeight: {
        "45px": "45px",
        "60px": "60px",
      },
      minHeight: {
        "6": "1.5rem",
        "8": "2rem",
        "165px": "165px",
        "180px": "180px",
      },
      maxHeight: {
        "540px": "540px",
      },
      maxWidth: {
        "screen-783": "783px",
        "540px": "540px",
        "830px": "830px",
        "1010px": "1010px",
        "screen-1440": "1440px",
        "screen-1920": "1920px",
      },
      outlineStyle: {
        DEFAULT: "solid",
      },
      outlineColor: {
        card: {
          DEFAULT: colors.neutral[300],
          active: colors.emerald[500],
        },
        DEFAULT: colors.black,
        focus: colors.black,
        "input-error": colors.red[500],
      },
      outlineOffset: {
        3: "3px",
      },
      outlineWidth: {
        1.5: "1.5px",
        3: "3px",
        DEFAULT: "1.5px",
      },
      ringColor: {
        "input-focus": colors.black,
      },
      ringOffsetWidth: {
        3: "3px",
      },
      ringWidth: {
        1.5: "1.5px",
      },
      screens: {
        midDesktop: "1155px",
        "1260px": "1260px",
        "3xl": "1920px",
      },
      textColor: {
        DEFAULT: customColors.gray[950],
        disabled: colors.neutral[500],
        error: colors.rose[600],
        icon: {
          DEFAULT: customColors.counterGreen[700],
          success: colors.white,
        },
        link: {
          primary: {
            DEFAULT: customColors.counterGreen[600],
            hover: customColors.counterGreen[700],
          },
          secondary: {
            DEFAULT: customColors.gray[500],
            hover: colors.black,
          },
        },
        muted: customColors.gray[500],
        placeholder: colors.neutral[400],
        secondary: colors.neutral[600],
        success: customColors.counterGreen[600],
        warning: customColors.orange[800],
      },
      transitionTimingFunction: {
        "out-back": "cubic-bezier(0.34, 1.56, 0.64, 1)",
        "out-quart": "cubic-bezier(0.25, 1, 0.5, 1)",
        "in-out-circ": "cubic-bezier(0.85, 0, 0.15, 1)",
      },
      width: {
        "150px": "150px",
        "260px": "260px",
        "270px": "270px",
        "400px": "400px",
        "450px": "450px",
        "460px": "460px",
        "500px": "500px",
        "540px": "540px",
        "610px": "610px",
        "945px": "945px",
        "1175px": "1175px",
      },
      zIndex: {
        sidebar: "30",
      },
    },
  },
  variants: {
    extend: {
      opacity: ["disabled"],
    },
  },
  plugins: [],
} satisfies Config;

const customTheme = config.theme;

export default config;
export { customTheme as theme };
