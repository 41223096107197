import { useMemo } from "react";

import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../../../tailwind.config";

const fullConfig = resolveConfig(tailwindConfig);

export enum SpinnerColor {
  GREEN,
  PURPLE,
}
export interface SpinnerProps {
  className?: string;
  color?: SpinnerColor;
}

export function Spinner({
  className,
  color = SpinnerColor.GREEN,
}: SpinnerProps) {
  const color1 = useMemo(() => {
    switch (color) {
      case SpinnerColor.PURPLE:
        return fullConfig.theme.colors.rewards[600];
      case SpinnerColor.GREEN:
      default:
        return fullConfig.theme.colors.counterGreen[300];
    }
  }, [color]);

  const color2 = useMemo(() => {
    switch (color) {
      case SpinnerColor.PURPLE:
        return fullConfig.theme.colors.rewards[400];
      case SpinnerColor.GREEN:
      default:
        return fullConfig.theme.colors.counterGreen[200];
    }
  }, [color]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
      aria-label="Loading"
      className={className}
    >
      <g transform="translate(50 50)">
        <g>
          <circle cx="17" r="5" fill={color1} opacity={0.05}>
            <animate
              attributeName="r"
              begin="-0.25s"
              dur="4s"
              keyTimes="0;0.5;1"
              repeatCount="indefinite"
              values="3.5999999999999996;8.399999999999999;3.5999999999999996"
            ></animate>
          </circle>
          <animateTransform
            attributeName="transform"
            begin="0s"
            dur="4s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="rotate"
            values="0;360"
          ></animateTransform>
        </g>
        <g>
          <circle cx="17" r="5" fill={color1} opacity={0.1}>
            <animate
              attributeName="r"
              begin="-0.2s"
              dur="2s"
              keyTimes="0;0.5;1"
              repeatCount="indefinite"
              values="3.5999999999999996;8.399999999999999;3.5999999999999996"
            ></animate>
          </circle>
          <animateTransform
            attributeName="transform"
            begin="-0.05s"
            dur="2s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="rotate"
            values="0;360"
          ></animateTransform>
        </g>
        <g>
          <circle cx="17" r="5" fill={color1} opacity={0.2}>
            <animate
              attributeName="r"
              begin="-0.15s"
              dur="1.3333333333333333s"
              keyTimes="0;0.5;1"
              repeatCount="indefinite"
              values="3.5999999999999996;8.399999999999999;3.5999999999999996"
            ></animate>
          </circle>
          <animateTransform
            attributeName="transform"
            begin="-0.1s"
            dur="1.3333333333333333s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="rotate"
            values="0;360"
          ></animateTransform>
        </g>
        <g>
          <circle cx="17" r="5" fill={color2} opacity={0.9}>
            <animate
              attributeName="r"
              begin="-0.1s"
              dur="1s"
              keyTimes="0;0.5;1"
              repeatCount="indefinite"
              values="3.5999999999999996;8.399999999999999;3.5999999999999996"
            ></animate>
          </circle>
          <animateTransform
            attributeName="transform"
            begin="-0.15s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="rotate"
            values="0;360"
          ></animateTransform>
        </g>
        <g>
          <circle cx="17" r="5" fill={color1}>
            <animate
              attributeName="r"
              begin="-0.05s"
              dur="0.8s"
              keyTimes="0;0.5;1"
              repeatCount="indefinite"
              values="3.5999999999999996;8.399999999999999;3.5999999999999996"
            ></animate>
          </circle>
          <animateTransform
            attributeName="transform"
            begin="-0.2s"
            dur="0.8s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="rotate"
            values="0;360"
          ></animateTransform>
        </g>
      </g>
    </svg>
  );
}
